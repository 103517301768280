// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-single-js": () => import("/opt/build/repo/src/templates/post/single.js" /* webpackChunkName: "component---src-templates-post-single-js" */),
  "component---src-templates-category-single-js": () => import("/opt/build/repo/src/templates/category/single.js" /* webpackChunkName: "component---src-templates-category-single-js" */),
  "component---src-templates-tag-single-js": () => import("/opt/build/repo/src/templates/tag/single.js" /* webpackChunkName: "component---src-templates-tag-single-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertise-with-us-js": () => import("/opt/build/repo/src/pages/advertise-with-us.js" /* webpackChunkName: "component---src-pages-advertise-with-us-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-articles-js": () => import("/opt/build/repo/src/pages/latest-articles.js" /* webpackChunkName: "component---src-pages-latest-articles-js" */),
  "component---src-pages-subscription-js": () => import("/opt/build/repo/src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

